import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import img1 from "../images/services/pit-optimization.webp";
import img2 from "../images/services/pit-optimization1.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

const Service4 = () => {
  const [isServicePage, setIsServicePage] = useState();

  let location = useLocation();
  useEffect(() => {
    if (location.pathname === "/services") {
      setIsServicePage(true)
    }else{
      setIsServicePage(false)
    }
  }, [])
  
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);

  return (
    <>
      {isServicePage===false && <Helmet>
        <meta
          name="description"
          content="Welcome to Rubra Mining, where we specialize in optimizing pit design, planning, and scheduling to maximize the economic value of your resources."
        />
        <title>Pit Optimization, Planning, and Scheduling - Rubra Mining Services </title>
      </Helmet>}
      <section className="bg-light" id="service4">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12 mx-auto text-center mb-4">
              <div className="ot-heading">
                <span>Service 4</span>
                <h3>
                  Pit Optimization, Planning, and Scheduling:
                  <br />
                  Maximizing Resource Value
                </h3>
                <p>
                  Welcome to Rubra Mining, where we specialize in optimizing pit
                  design, planning, and scheduling to maximize the economic
                  value of your resources.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="service-content-box">
                <h4>Our Approach</h4>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Ore Body Understanding</h4>
                  <ul>
                    <li>Thoroughly understand the ore body</li>
                    <li>
                      Articulate uncertainty to develop a range of pit limits.
                    </li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Practical Mine Designs and Sequencing</h4>
                  <ul>
                    <li>
                      {" "}
                      Support the development of practical mine designs and
                      sequencing.
                    </li>
                    <li>Ensure safe and executable mine plans.</li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Production Plan Development</h4>
                  <ul>
                    <li>Develop production plans at all horizons.</li>
                    <li>
                      Ensure alignment between the plan and business objectives.
                    </li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4> Plan Reviews and Due Diligence</h4>
                  <ul>
                    <li>Conduct plan reviews and due diligence.</li>
                    <li>
                      Articulate risks and opportunities from both operational
                      and technical viewpoints.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <LazyLoadImage
                  src={img1}
                  className="img-fluid sticky"
                  alt="Pit Optimization"
                />
              </div>
            </div>
          </div>

          <div className="row  pt-50">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 pe-lg-5">
                <div className="about-image">
                  <LazyLoadImage
                    src={img2}
                    className="img-fluid sticky"
                    alt="Pit Optimization"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-12 ">
                <div className="service-content-box">
                  <div className=" ot-heading mb-0">
                    <h3>Successes in planning, scheduling and optimisation</h3>
                  </div>
                  <p>
                    Our commitment to excellence is showcased through the
                    following projects and achievements:
                  </p>

                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4>Process-Driven Mine Planning Models</h4>
                    <ul>
                      <li>
                        {" "}
                        Led the development of process flow-driven mine planning
                        models for short to long term planning to ensured
                        scheduling is user-friendly and supports audits of mine
                        plans.
                      </li>
                      <li>
                        Implemented across short-term and life-of-mine plan
                        horizons across all Pilbara operations of a large iron
                        ore miner (14+ mine operations).
                      </li>
                    </ul>
                  </div>
                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4> Simulation Model for Grade Conformance</h4>
                    <ul>
                      <li>
                        Developed a simulation model to analyze grade
                        conformance for a group of iron ore mines blend.
                      </li>
                      <li>
                        Ascertained the impact of non-conformances on individual
                        mine sites on combined grade conformance.
                      </li>
                      <li>
                        Collaborated with the marketing team on final product
                        conformance and variability analysis.
                      </li>
                    </ul>
                  </div>
                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4> Transition Management</h4>
                    <ul>
                      <li>
                        Successfully managed the transition of the long-term
                        planning process from Resource Development to the
                        Technical Services team.
                      </li>
                      <li>
                        Developed and implemented a mine planning framework for
                        0 to 5-year planning for a group of mines in Pilbara.
                      </li>
                      <li>
                        At Rubra Mining, we are dedicated to optimizing your
                        mining operations through innovative pit optimization,
                        planning, and scheduling, ensuring the maximization of
                        your resource value.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service4;
