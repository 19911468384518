import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { NavLink } from "react-router-dom";


const OurServicesSection = ({ image, desc, title, link }) => {
  return (
    <>
      
      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 d-flex">
        <div className="icon-box-s3 i7 p-0">
          <div className="top-box mb-2">
            <div className="">
              <LazyLoadImage
                src={image}
                alt="services"
                className="img-fluid"
              />
            </div>
          </div>
          <div>

          <div className="learn-more-card-body p-4 pb-5">
            <h5>{title}</h5>
            <p>
              {desc}
            </p>
            <NavLink to={link} className="btn-details text-decoration-none">
              <FaArrowRight/> LEARN MORE
            </NavLink>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServicesSection;
