import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import img1 from "../images/services/operational-support.webp";
import img2 from "../images/services/operational-support1.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

const Service2 = () => {
  const [isServicePage, setIsServicePage] = useState();

  let location = useLocation();
  useEffect(() => {
    if (location.pathname === "/services") {
      setIsServicePage(true)
    }else{
      setIsServicePage(false)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);

  return (
    <>
      {isServicePage===false && <Helmet>
        <meta
          name="description"
          content="At Rubra Mining, our Operational Support services employ advanced analytics and technical expertise to thoroughly examine every facet of mine operations throughout the value chain. We leverage these insights to pinpoint real improvements, achieving excellence in performance."
        />
        <title>Operational Support - Rubra Mining Services</title>
      </Helmet>}
      <section className="bg-light" id="service2">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12 mx-auto text-center mb-4">
              <div className="ot-heading">
                <span>Service 2</span>
                <h3>Operational Support: Enhancing Operational Excellence</h3>
                <p>
                  At Rubra Mining, our Operational Support services employ
                  advanced analytics and technical expertise to thoroughly
                  examine every facet of mine operations throughout the value
                  chain. We leverage these insights to pinpoint real
                  improvements, achieving excellence in performance.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="service-content-box">
                <h4>Key Offerings:</h4>
                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Coaching Operations Leaders</h4>
                  <ul>
                    <li>
                      {" "}
                      Provide coaching to operations leaders for a deeper
                      technical understanding of mine operations.
                    </li>
                    <li>
                      Ensure mine plan conformance through targeted guidance.
                    </li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>On-Site Partnering Process</h4>
                  <ul>
                    <li>
                      Undertake on-site partnering to unlock significant value
                      for the mine.
                    </li>
                    <li>
                      Implement cost reduction or volume uplift programs for
                      tangible results.
                    </li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Design Sustainable Improvement Programs</h4>
                  <ul>
                    <li>
                      Design processes and key performance indicators for a
                      sustainable improvement program
                    </li>
                    <li>
                      Ensure continual enhancement of operational efficiency.
                    </li>
                  </ul>
                </div>
                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Project Management Resources</h4>
                  <ul>
                    <li>
                      Provide coverage and project management resources for mine
                      operations engineering and leadership roles.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <LazyLoadImage
                  src={img1}
                  className="img-fluid sticky"
                  alt="operational support"
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-50">
            <div className="col-lg-6 col-md-12 pe-lg-5">
              <div className="about-image">
                <LazyLoadImage
                  src={img2}
                  className="img-fluid sticky "
                  alt="operational support"
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="service-content-box">
                <div className=" ot-heading mb-0">
                  <h3>Achievements in operational management</h3>
                </div>
                <p>
                  Our track record includes substantial contributions to safety
                  initiatives and notable achievements, such as:
                </p>
                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Tier A Gold Mine</h4>
                  <ul>
                    <li>
                      Successfully designed and implemented daily, weekly, and
                      monthly reconciliation processes for the mine value
                      stream.
                    </li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4> Capacity Utilization Focus</h4>
                  <ul>
                    <li>
                      Improved asset capacity utilization, achieving the best
                      production records through debottlenecking and strategy
                      alignment.
                    </li>
                    <li>
                      {" "}
                      Introduced "Theory of Constraints" at the operational
                      level for enhanced efficiency.
                    </li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Large-Scale Copper/Gold Mine</h4>
                  <ul>
                    <li>
                      Successfully led the Transformation workstream for the
                      open pit mine, HME maintenance, Mine Technical Services,
                      and Asset and Material Management
                    </li>
                    <li>
                      At Rubra Mining, our Operational Support services are
                      designed to elevate your mine operations to new heights by
                      ensuring technical proficiency, value realization, and
                      sustained improvement.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service2;
