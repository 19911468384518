import React, { useEffect } from "react";
import ServicesArray from "./Services_Data";
import HeroSection from "./HomePage_Components/HeroSection";
import SolutionsDataArray from "./HomePage_Components/HomePageSolutions_Data";
import OurServicesSection from "./HomePage_Components/OurServicesSection";
import { Helmet } from "react-helmet";
import OurSolutionsSection from "./HomePage_Components/OurSolutionsSection";

const Home = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);
  
  return (
    <>
      <Helmet>
        <meta name="description" content="Rubra Mining Services Extracting Efficiency Maximising Results endeavours to be at the forefront of the mining industry, offering comprehensive solutions. Our seasoned team of professionals brings forth a wealth of multidisciplinary experience in various commodities such as Coal, Iron Ore, Copper, and Gold." />
        <title>Rubra Mining Services PTY LTD</title>
      </Helmet>

      {/* section 1 */}
      <HeroSection />

      {/* section 2 */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ot-heading">
                <span> Our Services</span>
                <h2 className="main-heading">Our Services Include:</h2>
              </div>
            </div>
            {ServicesArray.map((elem, key) => {
              return (
                <OurServicesSection
                  key={key}
                  image={elem.home_image}
                  desc={elem.home_desc}
                  title={elem.home_heading}
                  link={elem.redirect_link}
                />
              );
            })}
          </div>
        </div>
      </section>

      {/* section 3 */}
      <section className="bg-dark-primary pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="ot-heading">
                <span className="text-primary-light">Solution</span>
                <h2 className="main-heading text-white">Our Solution</h2>
              </div>
              <div className="space-20"></div>
            </div>
          </div>
        </div>
        <div className="container-fluid px-lg-0">
          <div className="row position-relative">
            {SolutionsDataArray.map((elem, key) => {
              return (
                <OurSolutionsSection
                  key={key}
                  title={elem.title}
                  image={elem.image}
                  content={elem.content}
                />
              );
            })}
          </div>
        </div>
      </section>

      {/* why-choose */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="ot-heading">
                <span> Why Choose Us</span>
                <h2 className="main-heading">Why Choose Rubra Mining?</h2>
              </div>
              <p className=" fs-15">
                At Rubra Mining Services, we transcend the conventional, going
                beyond service provision to become your strategic partners in
                achieving operational excellence and unparalleled growth in the
                dynamic mining industry. Choose us to experience the seamless
                integration of expertise, innovation, and results-driven
                solutions that will propel your mining operations to new
                heights.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
