import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const OurSolutionsSection = ({ title, content, image }) => {
  return (
    <>
      <div className="home-page-solutions-alignment col-lg-6 col-md-6 mb-4 mb-lg-0">
        <LazyLoadImage
          src={image}
          alt={title}
          className="img-fluid d-lg-block d-none"
        />
        <div className=" solution-desc-box  bg-white transform-up">
          <h5>{title}</h5>
          <p>
            {content}
          </p>
        </div>
      </div>
    </>
  );
};

export default OurSolutionsSection;
