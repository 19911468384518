import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import img1 from "../images/services/system-design-support.webp";
import img2 from "../images/services/system-design-support1.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

const Service5 = () => {
  const [isServicePage, setIsServicePage] = useState();

  let location = useLocation();
  useEffect(() => {
    if (location.pathname === "/services") {
      setIsServicePage(true)
    }else{
      setIsServicePage(false)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);

  return (
    <>
      {isServicePage===false && <Helmet>
        <meta
          name="description"
          content="At Rubra Mining, we are dedicated to optimizing your operational processes and systems, ensuring seamless alignment with your business objectives. Our track record of developing innovative solutions underscores our commitment to operational excellence."
        />
        <title>Process and System Design Support - Rubra Mining Services </title>
      </Helmet>}
      <section>
        <div className="container" id="service5">
          <div className="row">
            <div className="col-lg-8 col-12 mx-auto text-center mb-4">
              <div className="ot-heading">
                <span>Service 5</span>
                <h3>
                  Process and System Design Support:
                  <br /> Driving Operational Excellencee
                </h3>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="service-content-box">
                <h4>Our Services</h4>
                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>All-Inclusive Process Design</h4>
                  <ul>
                    <li>
                      {" "}
                      Utilize operational and technical expertise to design
                      processes that comprehensively deliver business
                      objectives.
                    </li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Bottleneck Analysis and De-bottlenecking</h4>
                  <ul>
                    <li>Review and analyze existing processes.</li>
                    <li>
                      Articulate bottlenecks and assist in developing
                      de-bottlenecking processes.
                    </li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Advanced Data Analytics</h4>
                  <ul>
                    <li>
                      Use advanced data analytics to spotlight areas of concern.
                    </li>
                    <li>
                      Support in designing key performance indicators (KPIs) and
                      subordinate steps to enhance performance.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <LazyLoadImage
                  src={img1}
                  className="img-fluid sticky"
                  alt="Process and System Design Support"
                />
              </div>
            </div>
          </div>

          <div className="row  pt-50">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 pe-lg-5">
                <div className="about-image">
                  <LazyLoadImage
                    src={img2}
                    className="img-fluid sticky"
                    alt="imProcess and System Design Supportage"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-12 ">
                <div className="service-content-box">
                  <div className=" ot-heading mb-0">
                    <h3>Key Achievements in process management</h3>
                  </div>
                  <p>
                    Our commitment to innovation and efficiency is reflected in
                    our projects and achievements:
                  </p>

                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4>Web-Based Mine Design Approval System</h4>
                    <ul>
                      <li>
                        {" "}
                        Conceptualized and developed a web-based Mine Design
                        Approval System
                      </li>
                      <li>
                        In use across Pilbara operations, it streamlines
                        workflow-managed documentation and approval of mine
                        designs.
                      </li>
                    </ul>
                  </div>
                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4>Mining Engineer systems and processes</h4>
                    <ul>
                      <li>
                        Managed mine planning tools, processes, and systems for
                        Pilbara operations or a large multi mine company to
                        provided crucial support to operations and planning
                        deliverables.
                      </li>
                    </ul>
                  </div>
                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4>Greenfield Projects and Operations in India, Australia and Mongolia</h4>
                    <ul>
                      <li>
                        Developed and embedded processes and systems for
                        technical teams in greenfield operations
                      </li>
                      <li>
                        {" "}
                        Covered areas such as Mine and Resource Geology,
                        Geotechnical, Mine Planning, Drill & Blast, and Tailings
                        Storage Facility
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 text-center pt-5">
                <p>
                  At Rubra Mining, we are dedicated to optimizing your
                  operational processes and systems, ensuring seamless alignment
                  with your business objectives. Our track record of developing
                  innovative solutions underscores our commitment to operational
                  excellence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service5;
