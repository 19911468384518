import home_img1 from "./images/background/safety-culture-support.webp";
import home_img2 from "./images/background/operational-support.webp";
import home_img3 from "./images/background/autonomy-and-analytics-support.webp";
import home_img4 from "./images/background/pit-optimization-planning.webp";
import home_img5 from "./images/background/process-and-system.webp";
import home_img6 from "./images/background/project-management.webp";


const ServicesArray = [
  {
    id: 1,
    home_image: home_img1,
    home_heading: "Safety Culture Support",
    redirect_link: '/services/safety-culture-support',
    home_desc:
      "Transform safety culture with specialized training for impactful interactions.",
  },
  {
    id: 2,
    home_image: home_img2,
    home_heading: "Operational Support",
    redirect_link: '/services/operational-support',
    home_desc:
      "Enhance mine operations with advanced analytics and coaching for sustainable improvements.",
  },
  {
    id: 3,
    home_image: home_img3,
    home_heading: "Autonomy and Analytics Support",
    redirect_link: '/services/autonomy-and-analytics-support',
    home_desc:
      "Revolutionize fleet operations with expertise in autonomous hauling, optimizing efficiency and performance.",
  },
  {
    id: 4,
    home_image: home_img4,
    home_heading: "Pit Optimisation, Planning and Scheduling",
    redirect_link: '/services/pit-optimisation-planning-and-scheduling',
    home_desc:
      "Maximize economic value through expert ore body comprehension, shaping practical mine designs aligned with business goals.",
  },
  {
    id: 5,
    home_image: home_img5,
    home_heading: "Process and System Design Support",
    redirect_link: '/services/process-and-system-design-support',
    home_desc:
      "Ignite operational excellence with precise solutions, reshaping efficiency and innovation.",
  },
  {
    id: 6,
    home_image: home_img6,
    home_heading: "Project Management",
    redirect_link: '/services/project-management',
    home_desc:
      "Empower projects with end-to-end support for on-time, on-budget delivery and effective problem-solving",
  },
];

export default ServicesArray;