import React from 'react'

const HeroSection = () => {
  return (
    <>
      <section className="home2-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div className="home2-top-right">
                <p className="small-text text-white">Rubra Mining Services</p>
                <h2> Extracting Efficiency Maximising Results</h2>
                <p className="sub-text text-white">
                  endeavours to be at the forefront of the mining industry,
                  offering comprehensive solutions. Our seasoned team of
                  professionals brings forth a wealth of multidisciplinary
                  experience in various commodities such as Coal, Iron Ore,
                  Copper, and Gold.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HeroSection
