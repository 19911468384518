import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Header";
import Home from "./Home";
import ProductsAndServices from "./ProductsAndServices";
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import ContactUs from "./ContactUs";
import GoToTop from "./GoToTop";
import Service1 from "./ServiceDetails/Service1";
import Service2 from "./ServiceDetails/Service2";
import Service3 from "./ServiceDetails/Service3";
import Service4 from "./ServiceDetails/Service4";
import Service5 from "./ServiceDetails/Service5";
import Service6 from "./ServiceDetails/Service6";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<><Header /> <Home /> <Footer /> </>} />
          <Route path="/services" element={<><Header /> <ProductsAndServices /> <Footer /> </>} />
          <Route path="/about-us" element={<><Header /> <AboutUs /> <Footer /> </>} />
          <Route path="/contact-us" element={<><Header /> <ContactUs /> <Footer /> </>} />
          <Route path="/services/safety-culture-support" element={<><Header /> <Service1/> <Footer /> </>} />
          <Route path="/services/operational-support" element={<><Header /> <Service2/> <Footer /> </>} />
          <Route path="/services/autonomy-and-analytics-support" element={<><Header /> <Service3/> <Footer /> </>} />
          <Route path="/services/pit-optimisation-planning-and-scheduling" element={<><Header /> <Service4/> <Footer /> </>} />
          <Route path="/services/process-and-system-design-support" element={<><Header /> <Service5/> <Footer /> </>} />
          <Route path="/services/project-management" element={<><Header /> <Service6/> <Footer /> </>} />
        </Routes>
      </BrowserRouter>
      <GoToTop />
    </>
  );
};

export default App;
