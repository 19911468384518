import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import img1 from "../images/services/system-optimization.webp";
import img2 from "../images/services/system-optimization1.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

const Service3 = () => {
  const [isServicePage, setIsServicePage] = useState();

  let location = useLocation();
  useEffect(() => {
    if (location.pathname === "/services") {
      setIsServicePage(true)
    }else{
      setIsServicePage(false)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);

  return (
    <>
      {isServicePage===false && <Helmet>
        <meta
          name="description"
          content="At Rubra Mining, we leverage extensive expertise in autonomous hauling fleet operations, particularly with CAT MineStar and Komatsu Frontrunner systems. Our Autonomy and Analytics Support services are designed to enhance your mining operations."
        />
        <title>Autonomy and Analytics Support - Products and Services </title>
      </Helmet>}
      <section id="service3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12 mx-auto text-center mb-4">
              <div className="ot-heading">
                <span>Service 3</span>
                <h3>
                  Autonomy and Analytics Support: <br />
                  Elevating Operational Efficiency
                </h3>
                <p>
                  At Rubra Mining, we leverage extensive expertise in autonomous
                  hauling fleet operations, particularly with CAT MineStar and
                  Komatsu Frontrunner systems. Our Autonomy and Analytics
                  Support services are designed to enhance your mining
                  operations by:
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="service-content-box">
                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>System Optimization</h4>
                  <ul>
                    <li>
                      Identify constraints within autonomous hauling systems
                    </li>
                    <li>Develop improvement plans to address constraints</li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Fleet Utilization and Efficiency Improvement</h4>
                  <ul>
                    <li>Enhance concurrent hang/queue management</li>
                    <li>Minimize on-haul stoppages due to obstructions</li>
                    <li>Reduce truck interchange delays</li>
                    <li>Optimize hauling speed</li>
                    <li>Minimize bunching and queuing</li>
                    <li>Improve overall system productivity</li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Mine Control System Enhancement</h4>
                  <ul>
                    <li>
                      Improve efficiencies of Mine Control systems and processes
                    </li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Asset Performance Improvement</h4>
                  <ul>
                    <li>
                      Incorporate autonomous operational data for asset
                      maintenance and defect elimination processes
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <LazyLoadImage
                  src={img1}
                  className="img-fluid sticky"
                  alt="System Optimization"
                />
              </div>
            </div>
          </div>
          <div className="row  pt-50">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 pe-lg-5">
                <div className="about-image">
                  <LazyLoadImage
                    src={img2}
                    className="img-fluid sticky"
                    alt="System Optimization"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-12 ">
                <div className="service-content-box">
                  <div className=" ot-heading mb-0">
                    <h3>Proven Success in managing Autonomy</h3>
                  </div>
                  <p>
                    Our commitment to excellence is demonstrated through the
                    following career achievements:
                  </p>

                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4>Komatsu FrontRunner system : Large-Scale Iron Ore Mine</h4>
                    <ul>
                      <li>
                        Successfully managed leading utilization and
                        productivity site with the Komatsu Frontrunner system
                      </li>
                    </ul>
                  </div>
                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4>CAT MineStar System: Large-Scale Deep Pit Gold Mine</h4>
                    <ul>
                      <li>
                        Managed one of the fastest implementations and ramp-ups
                        of the CAT MineStar system, achieving the quickest 100Mt
                        moved milestone.
                      </li>
                    </ul>
                  </div>
                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4>Productivity Enhancement</h4>
                    <ul>
                      <li>
                        Achieved a significant improvement (10%+) in CAT
                        MineStar utilization and productivity within 12 months
                        through debottlenecking and system improvement.
                      </li>
                    </ul>
                  </div>
                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4>Industry Collaboration</h4>
                    <ul>
                      <li>
                        Built strong connections with Autonomy OEMs development
                        and Ops support senior leadership team through
                        collaboration, product feature enhancement, and
                        continuous improvement.
                      </li>
                      <li>
                        At Rubra Mining, we are committed to optimizing your
                        mining operations through advanced autonomy and
                        analytics support, ensuring efficiency, productivity,
                        and continuous improvement.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service3;
