import React from "react";
import { NavLink } from "react-router-dom";
import { CiLinkedin } from "react-icons/ci";

const Footer = () => {
  return (
    <>
      <footer id="site-footer" className="site-footer footer-v1">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="widget-footer">
                <h5 className="text-white">About Us</h5>
                <p>
                  Welcome to Rubra Mining Services. Our commitment is to
                  redefine the standard, offering tailor-made, comprehensive
                  solutions driven by a seasoned team of professionals with
                  unparalleled expertise in commodities such as Coal, Iron Ore,
                  Copper, and Gold.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="widget-footer">
                <h5 className="text-white">Services</h5>
                  <ul className="list-items">
                  <li>
                    <NavLink className="text-decoration-none" to="/services/safety-culture-support">
                      Safety Culture Support
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="text-decoration-none" to="/services/operational-support">
                      Operational Support
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="text-decoration-none" to="/services/autonomy-and-analytics-support">
                      Autonomy and Analytics Support
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="text-decoration-none" to="/services/pit-optimisation-planning-and-scheduling">
                      Pit Optimisation, Planning and Scheduling
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="text-decoration-none" to="/services/process-and-system-design-support">
                      Process and System Design Support
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="text-decoration-none" to="/services/project-management">
                      Project Management
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
              <div className="widget-footer">
                <h5 className="text-white">Company</h5>
                <ul className="list-items">
                  <li className="list-item">
                    <NavLink className="text-decoration-none" to="/about-us">About </NavLink>
                  </li>
                  <li className="list-item">
                    <NavLink className="text-decoration-none" to="/contact-us">Contact Us </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
              <div className="widget-footer">
                <h5 className="text-white">Follow Us</h5>
                <a
                  href="https://www.linkedin.com/company/rubra-mining-services-pty-ltd"
                  target="_self"
                  className="text-white"
                >
                  <CiLinkedin className="footer-linkedin-icon"/>
                </a>
              </div>
            </div>
          </div>
          <div className="row border-top pt-2">
            <div className="col-md-12 text-center">
              <p className="copyright-text">
                Copyright © 2024 All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
