import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import axios from 'axios'

const ContactUs = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
 
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios({
        method: 'post',
        url: '/php_server/mail.php',
        headers: { 'content-type': 'application/json'},
        data: {name, phone, email, subject, message}
      })

      alert(response.data.message);
      setName('');
      setPhone('');
      setEmail('');
      setSubject('');
      setMessage('');
    } catch (e) {
      console.log(e)
    }

  }


  return (
    <>
      <Helmet>
        <meta name="description" content="Do you have questions or inquiries? Reach out to us today to request quotes or simply to learn more about our products and services. You can contact our team via phone or email for tailored solutions." />
        <title>Contact Us - Rubra Mining Services</title>
      </Helmet>

      <section className="contact-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-info">
                <div className="ot-heading">
                  <h2>Contact Us</h2>
                </div>
                <p>
                  Do you have questions or inquiries?  Reach out to us today to request quotes or simply to learn more about our products and services. You can contact our team via phone or email for tailored solutions.
                </p>
                <ul className="p-0 mt-3">
                  <li>
                    <IoMdMail className="icons" />
                    info@rubramining.com
                  </li>
                  <li>
                    <BsFillTelephoneFill className="icons" />
                    +61 408903654
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-message">
                <form id="contact-form" className="contact-form" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <input
                        name="name"
                        placeholder="Name *"
                        type="text"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <input
                        name="phone"
                        placeholder="Phone *"
                        type="text"
                        required
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <input
                        name="email"
                        placeholder="Email *"
                        type="text"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <input
                        name="subject"
                        placeholder="Subject *"
                        type="text"
                        required
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <div className="contact2-textarea text-center">
                        <textarea
                          placeholder="Message *"
                          name="message"
                          className="form-control2"
                          required=""
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="contact-btn">
                        <button className="btn-details" type="submit">
                          <FaArrowRight className="icon" />
                          Send Message
                        </button>
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                      <p className="form-messege"></p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
