import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import img1 from "../images/services/safety-culture-support.webp";
import img2 from "../images/services/safety-culture-support1.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

const Service1 = () => {
  const [isServicePage, setIsServicePage] = useState();

  let location = useLocation();
  useEffect(() => {
    if (location.pathname === "/services") {
      setIsServicePage(true)
    }else{
      setIsServicePage(false)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);


  return (
    <>
      {isServicePage===false && <Helmet>
        <meta
          name="description"
          content="At Rubra Mining, we are dedicated to fostering a robust safety culture within your operations. Our comprehensive Safety Culture Support services encompass: On-the-Ground Support, Training, and Coaching, Incident Management and Safety Plan Implementation, Statutory Support Coverage."
        />
        <title>Safety Culture Support - Rubra Mining Services</title>
      </Helmet>}
      <section id="service1">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12 mx-auto text-center mb-4">
              <div className="ot-heading">
                <span>Service 1</span>
                <h3>Safety Culture Support: On the Ground Expertise</h3>
                <p>
                  At Rubra Mining, we are dedicated to fostering a robust safety
                  culture within your operations. Our comprehensive Safety
                  Culture Support services encompass:
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="service-content-box">
                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>On-the-Ground Support, Training, and Coaching:</h4>
                  <ul>
                    <li> Effective Safety Interactions</li>
                    <li>Critical Control Verifications</li>
                    <li>Successful Pre-Start Meetings</li>
                    <li>Leaders' Engagement in the Field</li>
                    <li>Risk Assessments and Development of Controls</li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Incident Management and Safety Plan Implementation:</h4>
                  <ul>
                    <li>
                      {" "}
                      Conducting/supporting incident investigations with
                      follow-up actions
                    </li>
                    <li>
                      Supporting the development and implementation of safety
                      management plans
                    </li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Statutory Support Coverage</h4>
                  <p>
                    Providing coverage for mine leadership as Quarry
                    Manager/Senior Site Executive
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <LazyLoadImage
                  src={img1}
                  className="img-fluid sticky"
                  alt="Safety Culture Support"
                />
              </div>
            </div>
          </div>
          <div className="row  pt-50">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 pe-lg-5">
                <div className="about-image">
                  <LazyLoadImage
                    src={img2}
                    className="img-fluid sticky"
                    alt="Safety Culture Support"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-12 ">
                <div className="service-content-box">
                  <div className=" ot-heading mb-0">
                    <h3>Proven Success in Safety</h3>
                  </div>
                  <p>
                    Our track record includes substantial contributions to
                    safety initiatives and notable achievements, such as:
                  </p>

                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4>Large Gold Mine</h4>
                    <ul>
                      <li>Achieved improved safety performance (AIFR, LTI)</li>
                      <li>
                        Recorded significant reduction in significant potential
                        incidents for managed opeartions
                      </li>
                    </ul>
                  </div>
                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4> Large Iron Ore Mine Operations</h4>
                    <ul>
                      <li>
                        Achieved an impressive 900+ days lost time injury-free
                        days as Ops Manager
                      </li>
                    </ul>
                  </div>
                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4> Leadership Recognition</h4>
                    <ul>
                      <li>
                        Key member of two leadership teams managing large
                        operations, both honored with CEO Safety Awards
                        (Copper/Gold Mine, Port Operations)
                      </li>
                    </ul>
                  </div>
                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4> Critical Risk Management</h4>
                    <ul>
                      <li>
                        Valuable member of the Critical Risk Management
                        deployment team at operational sites
                      </li>
                    </ul>
                  </div>
                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4>Employee Engagement</h4>
                    <ul>
                      <li>
                        Significantly improved Employee Net Promoter Score
                        (eNPS) for managed teams and operations At Rubra Mining,
                        we are committed to creating a safety-centric
                        environment through hands-on support, training, and a
                        proven track record of safety excellence.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service1;
