import React from "react";
import logo from "./images/logo/rubra_logo.png";
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IoMdMail } from "react-icons/io";
import { BsFillTelephoneFill } from "react-icons/bs";
import { CiLinkedin } from "react-icons/ci";

const Header = () => {
  const handleScroll = () => {
    var siteHeader = document.getElementById('site-header');
    if (siteHeader.classList.contains("sticky-header")) {
      var siteHeaderHeight = siteHeader.offsetHeight + 30;

      if (window.scrollY >= siteHeaderHeight) {
        document.querySelectorAll('.sticky-header .octf-main-header, .mobile-header-sticky .header_mobile').forEach(function (element) {
          element.classList.add('is-stuck');
        });
      } else {
        document.querySelectorAll('.sticky-header .octf-main-header, .mobile-header-sticky .header_mobile').forEach(function (element) {
          element.classList.remove('is-stuck');
        });
      }
    }
  };

  window.addEventListener("scroll", handleScroll);

  return (
    <>
      <header id="site-header" className="site-header sticky-header">
        <div className="octf-main-header">
          <div className="header-topbar">
            <div className="octf-area-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <ul className="topbar-info">
                      <li className=" d-flex align-items-center"><IoMdMail /><a href="mailto:info@rubramining.com" className="text-decoration-none m-1"> info@rubramining.com</a></li>
                      <li className=" d-flex align-items-center"><BsFillTelephoneFill /><a href="tel:+61 408903654" className="text-decoration-none m-1"> +61 408903654</a></li>
                    </ul>
                  </div>
                  <div className="col-md-6 text-right">
                    <div className="topbar-right">

                      <ul className="social-list">
                        <li><a
                          href="https://www.linkedin.com/company/rubra-mining-services-pty-ltd"
                          target="_self"
                        >
                          <CiLinkedin className="footer-linkedin-icon" />
                        </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="octf-area-wrap">
            <div className="container octf-mainbar-container">
              <div className="octf-mainbar">
                <div className="octf-mainbar-row octf-row">
                  <div className="octf-col logo-col">
                    <div id="site-logo" className="site-logo">
                      <NavLink to="/">
                        <LazyLoadImage src={logo} alt="Engrubra_logoitech" className="" />
                      </NavLink>
                    </div>
                  </div>
                  <div className="octf-col menu-col">
                    <nav id="site-navigation" className="main-navigation">
                      <ul className="menu">
                        <li><NavLink to="/services">Products and Services</NavLink>
                        </li>

                        <li><NavLink to="/about-us">Company</NavLink> </li>
                        <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header_mobile">
          <div className="container">
            <div className="mlogo_wrapper clearfix">
              <div className="mobile_logo">
                <NavLink to="/">
                  <LazyLoadImage src={logo} alt="rubra_logo" />
                </NavLink>
              </div>
              <div id="mmenu_toggle">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
              </div>
            </div>
            <div className="mmenu_wrapper">
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul id="menu-main-menu" className="mopbile-header-menu p-0">
                  <p className="mb-2"><NavLink to="/services" className='mobile-header-links'>Products and Services</NavLink>
                  </p>
                  <p className="mb-2"><NavLink to="/about-us" className='mobile-header-links'>Company</NavLink> </p>
                  <p className="mb-2"><NavLink to="/contact-us" className='mobile-header-links'>Contact Us</NavLink></p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
