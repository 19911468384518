import React, { useEffect } from "react";
import ServicesArray from "./Services_Data";
import { Helmet } from "react-helmet";
import Service1 from "./ServiceDetails/Service1";
import Service2 from "./ServiceDetails/Service2";
import Service3 from "./ServiceDetails/Service3";
import Service4 from "./ServiceDetails/Service4";
import Service5 from "./ServiceDetails/Service5";
import Service6 from "./ServiceDetails/Service6";

const ProductsAndServices = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="At Rubra Mining, we provide range of services. Some of them are: Safety Culture Support, Operational Support, Autonomy and Analytics Support, Pit Optimization, Planning, and Scheduling, Process and System Design Support, Project Management Excellence."
        />
        <title>Products and Services - Rubra Mining</title>
      </Helmet>

      <section className=" banner service-page-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12 mb-4">
              <div className="ot-heading">
                <span className="text-white"> OUR SERVICES</span>
                <h2 className="main-heading text-white mb-4">
                  Our Services Include
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Service1 />
      <Service2 />
      <Service3 />
      <Service4 />
      <Service5 />
      <Service6 />
    </>
  );
};

export default ProductsAndServices;
