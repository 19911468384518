import solutions_about_page_img1 from "../images/background/holistic-mining-expertise.webp";
import solutions_about_page_img2 from "../images/background/autonomy-expertise.webp";
import solutions_about_page_img3 from "../images/background/operational-brilliance.webp";
import solutions_about_page_img4 from "../images/background/industry-insights-and-collaboration.webp";

const SolutionsDataArray = [
  {
    id: 1,
    title: "Holistic Mining Expertise",
    image: solutions_about_page_img1,
    content:
      "We deliver not just solutions but holistic mining expertise, uniquely positioned to offer comprehensive support in mining leadership, operations, and technical proficiency.",
  },
  {
    id: 2,
    title: "Autonomy Expertise",
    image: solutions_about_page_img2,
    content:
      "Autonomy Expertise with Proven Results: Our team possesses extensive autonomy experience, with proven success in operating both CAT and Komatsu systems.",
  },
  {
    id: 3,
    title: "Operational Brilliance",
    image: solutions_about_page_img3,
    content:
      "Robust Technical and Operational Prowess: Experience the benefits of our robust mining technical and operational prowess, fortified by extensive hands-on experience across various functions. Operational Excellence Support: We actively support operational teams in delivering heightened safety, production, and productivity, ensuring a holistic enhancement of your mining operations. Strategic Guidance for Execution: Our role extends to guiding technical teams in operationalizing plans across various planning horizons, ensuring seamless execution and alignment with long-term business objectives.",
  },
  {
    id: 4,
    title: "Industry Insights and Collaboration",
    image: solutions_about_page_img4,
    content:
      "Industry Insights and Resources: Leveraging an extensive network in the industry, we draw on invaluable insights, resources, and solutions. Seamless Integration and Collaboration: We seamlessly integrate the work of operational and technical teams, fostering collaboration that enhances efficiency and effectiveness in your mining operations.",
  },
];

export default SolutionsDataArray;
