import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import img1 from "./images/background/about-us.webp";
import img2 from "./images/background/about-us-img.webp";

const AboutUs = () => {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Welcome to Rubra Mining: Empowering Your Success Through Unmatched Efficiency Our commitment is to redefine the standard, offering tailor-made, comprehensive solutions driven by a seasoned team of professionals with unparalleled expertise in commodities such as Coal, Iron Ore, Copper, and Gold."
        />
        <title>About Us - Rubra Mining Services</title>
      </Helmet>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <LazyLoadImage src={img1} alt="Rubra Mining" className="img-fluid" />
            </div>
            <div className="col-lg-6">
              <div className="ot-heading about-us-wrapper">
                <span> About Us</span>
                <h3 className="main-heading">
                  Welcome to Rubra Mining:
                  <br /> Empowering Your Success Through Unmatched Efficiency
                </h3>
              </div>
              <p>
                Our commitment is to redefine the standard, offering
                tailor-made, comprehensive solutions driven by a seasoned team
                of professionals with unparalleled expertise in commodities such
                as Coal, Iron Ore, Copper, and Gold.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-theme text-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ot-heading">
                <h2 className="main-heading text-white">Our Goal</h2>
              </div>
              <p className="text-white">
                Supporting clients in making complex, value-chain-wide decisions
                at speed to deliver optimal outcomes.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="ot-heading">
                <h2 className="main-heading">Guiding Values</h2>
              </div>
              <ul>
                <li className="mb-2">
                  <b>CAPABILITY FOCUS: </b>Crafting an optimized value proposition across all planning horizons for sustainable business goals.
                </li>
                <li className="mb-2">
                  <b>INTEGRATED:</b> Providing a holistic suite of mining services under one roof.
                </li>
                <li className="mb-2">
                  <b> AGILE & INNOVATIVE:</b> Embracing agility and prioritizing speed in innovation.
                </li>
                <li className="mb-2">
                  <b> INTELLIGENT:</b> Infusing decision-making steps into operational routines for swift, optimized decisions.
                </li>
                <li className="mb-2"><b>CUSTOMER-CENTRIC:</b> Leveraging our expertise, tools, and resources for a meaningful impact and heightened customer satisfaction.</li>
              </ul>


            </div>
            <div className="col-lg-6">
              <LazyLoadImage src={img2} alt="Rubra Mining" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
