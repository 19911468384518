import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import img1 from "../images/services/project-management.webp";
import img2 from "../images/services/project-management1.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

const Service6 = () => {
  const [isServicePage, setIsServicePage] = useState();

  let location = useLocation();
  useEffect(() => {
    if (location.pathname === "/services") {
      setIsServicePage(true)
    }else{
      setIsServicePage(false)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);

  return (
    <>
      {isServicePage===false && <Helmet>
        <meta
          name="description"
          content="At Rubra Mining, we are your dedicated partner for a diverse range of projects, whether they are new initiatives, improvement projects, debottlenecking endeavours, or straight problem-solving projects."
        />
        <title>Project Management Excellence - Rubra Mining Services </title>
      </Helmet>}
      <section className="bg-light" id="service6">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12 mx-auto text-center mb-4">
              <div className="ot-heading">
                <span>Service 6</span>
                <h3>
                  Project Management Excellence: <br />
                  Your Partner from Ideation to Implementation
                </h3>
                <p>
                  At Rubra Mining, we are your dedicated partner for a diverse
                  range of projects, whether they are new initiatives,
                  improvement projects, debottlenecking endeavours, or straight
                  problem-solving projects.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="service-content-box">
                <h4>Our Comprehensive Project Management Services:</h4>
                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>End-to-End Project Management</h4>
                  <ul>
                    <li>
                      {" "}
                      Take full responsibility for project management, from
                      ideation/initiation to successful conclusion.
                    </li>
                    <li>
                      {" "}
                      Provide a complete end-to-end, outcome-based project
                      delivery service.
                    </li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4>Schedule and Budget Management</h4>
                  <ul>
                    <li>Ensure projects stay on schedule and on budget.</li>
                    <li>
                      Identify business opportunities, review feasibilities, and
                      mitigate risks throughout the project lifecycle.
                    </li>
                  </ul>
                </div>

                <div className="service-content-text">
                  <FaCheck className="checkIcon" />
                  <h4> Problem-Solving Support</h4>
                  <ul>
                    <li>Provide support for problem-solving at any stage.</li>
                    <li>
                      Assure clients that potential major issues can be
                      controlled and mitigated.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <LazyLoadImage
                  src={img1}
                  className="img-fluid sticky"
                  alt="Project Management Excellence"
                />
              </div>
            </div>
          </div>

          <div className="row  pt-50">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 pe-lg-5">
                <div className="about-image">
                  <LazyLoadImage
                    src={img2}
                    className="img-fluid sticky"
                    alt="Project Management Excellence"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-12 ">
                <div className="service-content-box">
                  <div className=" ot-heading mb-0">
                    <h3>Key project successes</h3>
                  </div>
                  <p>
                    Our proven track record in project management includes the
                    successful management of various initiatives, such as:
                  </p>

                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4>Mine Design Approval System</h4>
                    <ul>
                      <li>
                        {" "}
                        Managed the project from ideation to implementation.
                      </li>
                      <li>
                        Engaged internal and external stakeholders and vendors
                        for seamless execution.
                      </li>
                    </ul>
                  </div>
                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4>Mine Planning Software and Workflow Enhancement</h4>
                    <ul>
                      <li>
                        Oversaw the development of mine planning software and
                        process-driven workflows for short and medium-term
                        goals.
                      </li>
                    </ul>
                  </div>
                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4>Challenging Mine Ore Recovery Project</h4>
                    <ul>
                      <li>
                        Managed a technical and operational joint project at a
                        geotechnically challenging copper/gold mine.
                      </li>
                      <li>
                        Successfully and safely recovered ore from
                        geotechnically sensitive zones.
                      </li>
                    </ul>
                  </div>
                  <div className="service-content-text">
                    <FaCheck className="checkIcon" />
                    <h4> Transformation Lead</h4>
                    <ul>
                      <li>
                        {" "}
                        Facilitated projects as a Transformation lead across the
                        mining value stream for a large mining operation.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 text-center">
                <p>
                  At Rubra Mining, we are committed to delivering excellence in
                  project management, ensuring the success of every endeavour,
                  and providing comprehensive support to overcome challenges at
                  every stage.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service6;
